const hostname = window.location.hostname;

let config = {
  login: {
    uri: 'https://login.hagerty.com/identity/connect/authorize?',
    brokerLogin: 'https://www.hagertybroker.ca/',
    profileUrl: 'https://members.hagerty.com/members/s/profile'
  },
  vehicles: {
    uri: 'https://platform.hagerty.com/graphql',
    apiKey: 'da2-22kq3nmcgbcopedzjl5evuplwa'
  },
  summary: {
    uri: 'https://www.hagerty.ca/apps/valuationtools/Api/'
  },
  latestArticles: {
    uri: 'https://n4lp4vvysvbodd4gvga4jfrdly.appsync-api.us-east-1.amazonaws.com/graphql',
    apiKey: 'da2-aoxydhpopjbjjgagvl3v6erk3m'
  },
  salesforce: {
    uri: 'https://platform.sandbox.hagerty.com/v1/campaigns/member',
    apiKey: '2tPevNGi6WaY2vonZSg1J3L1f3z3a4Ia6GDCtS0z',
    campaignId: '7011I000000WUfaQAG'
  },
  tealium: {
    tag: 'prod'
  },
  partnerModule: {
    uri: 'https://ccwebapi.azurewebsites.net/api/vehicles',
    auth: 'amx prod_714081fad7264381938c07823bb5b753'
  },
  midLayer: {
    uri: 'https://t8ojds48jf.execute-api.us-east-1.amazonaws.com/api/graphql/vid'
  }
};

if (hostname === 'localhost') {
  config = {
    login: {
      uri: 'https://login.hagerty.com/identity/connect/authorize?',
      brokerLogin: 'https://www.hagertybroker.ca/',
      profileUrl: 'https://members.hagerty.com/members/s/profile'
    },
    vehicles: {
      uri: 'https://platform.ci.hagerty.com/graphql',
      apiKey: 'da2-lt3eza42cnaz3cp7woxweld5qu'
    },
    summary: {
      uri: 'https://www.hagerty.ca/apps/valuationtools/Api/'
    },
    latestArticles: {
      uri: 'https://ixgnsh3prnh5nkhck5wefmup6u.appsync-api.us-east-1.amazonaws.com/graphql',
      apiKey: 'da2-dyhk7ssuhjb3ppqogawldzt5fq'
    },
    salesforce: {
      uri: 'https://platform.sandbox.hagerty.com/v1/campaigns/member',
      apiKey: 'pnj6paapWm7hJCtrRB7aT7DFUi573Rqn31juUNvk',
      campaignId: '7011I000000WRG3QAO'
    },
    tealium: {
      tag: 'dev'
    },
    partnerModule: {
      uri: 'https://ccwebapi.azurewebsites.net/api/vehicles',
      auth: 'amx prod_714081fad7264381938c07823bb5b753'
    },
    midLayer: {
      uri: 'https://ccu84dontb.execute-api.us-east-1.amazonaws.com/api/graphql/vid'
    }
  };
} else if (hostname === 'mo.hagerty.ca') {
  config = {
    login: {
      uri: 'https://mologin.hagerty.com/identity/connect/authorize?',
      brokerLogin: 'https://mo.hagertybroker.ca/',
      profileUrl: 'https://mo-hagerty.cs92.force.com/members/s/profile'
    },
    vehicles: {
      uri: 'https://platform.preprod.hagerty.com/graphql',
      apiKey: 'da2-gw6vgbeegnaihncpi3g3euw3ou'
    },
    summary: {
      uri: 'https://mo.hagerty.ca/apps/valuationtools/Api/'
    },
    latestArticles: {
      uri: 'https://2klr23dbkrgmfjv7aka7onmp7a.appsync-api.us-east-1.amazonaws.com/graphql',
      apiKey: 'da2-626hqjz6azbn3dptf77fjwlmva'
    },
    salesforce: {
      uri: 'https://platform.ci.hagerty.com/v1/campaigns/member',
      apiKey: '5Cxh5UiHlt9ONHzYs9qET7Qb1Bbb7nXT5MMuiDNM',
      campaignId: '7011I000000WRG3QAO'
    },
    tealium: {
      tag: 'qa'
    },
    partnerModule: {
      uri: 'https://ccwebapi.azurewebsites.net/api/vehicles',
      auth: 'amx prod_714081fad7264381938c07823bb5b753'
    },
    midLayer: {
      uri: 'https://asx1z21lza.execute-api.us-east-1.amazonaws.com/api/graphql/vid'
    }
  };
} else if (hostname === 'qa.hagerty.ca') {
  config = {
    login: {
      uri: 'https://qalogin.hagerty.com/identity/connect/authorize?',
      brokerLogin: 'https://qa.hagertybroker.ca/',
      profileUrl: 'https://qa-hagerty.cs198.force.com/members/s/profile'
    },
    vehicles: {
      uri: 'https://platform.ci.hagerty.com/graphql',
      apiKey: 'da2-lt3eza42cnaz3cp7woxweld5qu'
    },
    summary: {
      uri: 'https://qa.hagerty.ca/apps/valuationtools/Api/'
    },
    latestArticles: {
      uri: 'https://ixgnsh3prnh5nkhck5wefmup6u.appsync-api.us-east-1.amazonaws.com/graphql',
      apiKey: 'da2-dyhk7ssuhjb3ppqogawldzt5fq'
    },
    salesforce: {
      uri: 'https://platform.ci.hagerty.com/v1/campaigns/member',
      apiKey: 'OVvq3wuxtZ8hpDRoow0n9mAtDjocbQm66vRAoUhf',
      campaignId: '7011I000000WRG3QAO'
    },
    tealium: {
      tag: 'qa'
    },
    partnerModule: {
      uri: 'https://ccwebapi.azurewebsites.net/api/vehicles',
      auth: 'amx prod_714081fad7264381938c07823bb5b753'
    },
    midLayer: {
      uri: 'https://ccu84dontb.execute-api.us-east-1.amazonaws.com/api/graphql/vid'
    }
  };
} else if (hostname === 'dev.hagerty.ca') {
  config = {
    login: {
      uri: 'https://devlogin.hagerty.com/identity/connect/authorize?',
      brokerLogin: 'https://dev.hagertybroker.ca/',
      profileUrl: 'https://dev-hagerty.cs92.force.com/members/s/profile'
    },
    vehicles: {
      uri: 'https://platform.ci.hagerty.com/graphql',
      apiKey: 'da2-lt3eza42cnaz3cp7woxweld5qu'
    },
    summary: {
      uri: 'https://dev.hagerty.ca/apps/valuationtools/Api/'
    },
    latestArticles: {
      uri: 'https://ixgnsh3prnh5nkhck5wefmup6u.appsync-api.us-east-1.amazonaws.com/graphql',
      apiKey: 'da2-dyhk7ssuhjb3ppqogawldzt5fq'
    },
    salesforce: {
      uri: 'https://platform.sandbox.hagerty.com/v1/campaigns/member',
      apiKey: 'pnj6paapWm7hJCtrRB7aT7DFUi573Rqn31juUNvk',
      campaignId: '7011I000000WRG3QAO'
    },
    tealium: {
      tag: 'dev'
    },
    partnerModule: {
      uri: 'https://ccwebapi.azurewebsites.net/api/vehicles',
      auth: 'amx prod_714081fad7264381938c07823bb5b753'
    },
    midLayer: {
      uri: 'https://4dglvl0rw3.execute-api.us-east-1.amazonaws.com/api/graphql/vid'
    }
  };
}

export default config;
