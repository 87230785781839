import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './services/serviceWorker';
import { ApolloProvider } from '@apollo/client';
import { vehicles } from './clients';
import { ReactComponent as Icons } from '@hagerty/design-system/src/assets/icons.svg';
import './index.scss';

import App from './containers/App/App';

ReactDOM.render(
  <React.StrictMode>
    <Icons style={{ display: 'none' }} />
    <ApolloProvider client={vehicles}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
