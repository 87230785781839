import React from 'react';
import dayjs from 'dayjs';

import './index.scss';

function SiteFooter() {
  const endYear = dayjs().format('YYYY');

  return (
    <footer className="site-footer site-footer_compact cf-footer">
      <div className="container container_center">
        <div className="cf-footer__copyright">
          {`©1996–${endYear} The Hagerty Group, LLC`}
        </div>
        <div className="cf-footer__links">
          <a
            href="https://www.hagerty.ca/corporate/privacy-policy"
            target="_blank"
          >
            Privacy
          </a>
        </div>
      </div>
    </footer>
  );
}

export default SiteFooter;
