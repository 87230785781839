import React, { useEffect, useState } from 'react';
import configs from 'configs';
import { page } from 'cache';
import './index.scss';

function SiteHeader({
  loggedIn,
  username,
  phoneNumber,
  onLoginClick,
  onLogoutClick
}) {
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);

  useEffect(() => {
    window.onclick = () => {
      if (accountMenuOpen) {
        setAccountMenuOpen(false);
      }
    };
  }, [accountMenuOpen]);

  let loginHtml = (
    <>
      <a
        className="site-header__eyebrow_link show_medium"
        onClick={onLoginClick}
        href="#"
      >
        Create account
      </a>
      <a className="site-header__eyebrow_link" href="#" onClick={onLoginClick}>
        Log in
      </a>
    </>
  );

  const handleDropdownToggle = (event) => {
    event.preventDefault();
    setAccountMenuOpen(!accountMenuOpen);
  };

  const handleGoHome = (e: any) => {
    e.preventDefault();
    page('home');
  };

  if (loggedIn) {
    loginHtml = (
      <>
        <a
          className="site-header__eyebrow_link show_medium"
          href={configs.login.brokerLogin}
        >
          Broker login
        </a>
        <span className="context-menu context-menu_right context">
          <a
            href="#"
            className={`site-header__eyebrow_link context-login-link ${
              accountMenuOpen ? 'is-selected' : 'collapsed'
            }`}
            data-toggle="context"
            data-target-elm-id="AccountMenuDropDown"
            onClick={handleDropdownToggle}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="site-header__icon icon"
              aria-hidden="true"
            >
              <use xlinkHref="#16-profile" />
            </svg>{' '}
            {username || 'My Account'}
          </a>
          <div
            className={`context-menu__drop-down ${
              accountMenuOpen ? 'is-selected' : 'collapsed'
            }`}
            id="AccountMenuDropDown"
          >
            <ul className="context-menu__nav">
              <li className="context-menu__nav-item">
                <a
                  className="context-menu__nav-item__link text_3"
                  href={configs.login.profileUrl}
                >
                  Account
                </a>
              </li>
              <li className="context-menu__nav-item">
                <a
                  className="context-menu__nav-item__link text_3"
                  onClick={onLogoutClick}
                >
                  Log out
                </a>
              </li>
            </ul>
          </div>
        </span>
      </>
    );
  }

  return (
    <header className="site-header" data-country-code="US">
      <div className="site-header__sticky-wrapper">
        <div className="site-header__eyebrow">
          <div className="site-header__eyebrow_wrapper container container_center">
            <span>
              <a
                rel="nofollow"
                href="tel:${phoneNumber}"
                className="site-header__eyebrow_link"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="site-header__icon icon"
                  aria-hidden="true"
                >
                  <use xlinkHref="#16-call" />
                </svg>
                <span className="hide_large">Call Us</span>
                <span className="show_large">{phoneNumber}</span>
              </a>
            </span>
            <div className="site-header__accountlinks">{loginHtml}</div>
          </div>
        </div>

        <div className="site-header__navbar container container_center is-empty">
          <a
            href="#"
            className="site-header__logo logo"
            aria-label="Hagerty Logo"
            onClick={handleGoHome}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              width="116"
              height="24"
              viewBox="0 0 116 24"
            >
              <path
                fill="#101213"
                fillRule="evenodd"
                d="M115.8,1.3l-5.2,13.4v7.9h-6.2v-7.9L99.6,3.5h-3.9v19.1h-6.5V3.5h-5.3V1.3h21.2l4.3,10.3l3.9-10.3L115.8,1.3z M77.6,1.3c2.4,0,3.9,0.6,4.9,1.5c1.2,1.1,1.9,3,1.9,4.7c-0.1,2-0.8,3.6-3.8,5.3l5.4,9.7h-6.7l-4.7-8.9v8.9h-6.2V1.3L77.6,1.3z M75.8,4.1h-1.2v7.3h1.2c2.3,0,3.5-1.2,3.5-3.7C79.3,5.3,78.2,4.1,75.8,4.1z M59.5,19.9h8.2v2.7H53.3V1.4h13.8v2.7h-7.6v6.4h6.4v2.7 h-6.4L59.5,19.9z M44.2,10.6h8v10.9c0,0-0.4,0.1-1,0.3c-0.7,0.2-0.7,0.3-1.5,0.5c0,0-2.9,0.7-4.8,0.7c-3.2,0-5.6-0.9-7.3-2.8 c-1.7-1.9-2.6-4.5-2.6-8c0-3.3,0.9-6,2.8-8.1C39.5,2,42,1.1,44.9,1c3.6-0.1,7,1.2,7.1,6.4l-2.8,0l-0.1-1c-0.4-2.2-1.4-3.4-3.5-3.3 C44,3.3,43,3.8,42.5,4.9c-0.6,1.3-0.9,3.8-0.9,7.4c0,3.4,0.2,5.7,0.7,6.9c0.5,1.2,1.3,1.8,2.6,1.8c1.5,0,2.3-0.9,2.3-2.6v-5.2h-3.1 L44.2,10.6z M6.2,1.4v9.1h5V1.4h6.2v20l6.2-20h6.5l6.2,21.3h-6.5L28.5,18h-6.7l-1.4,4.6h-9.2v-9.5h-5v9.5H0V1.4L6.2,1.4z M25.3,6.5 l-2.8,9.4h5.5L25.3,6.5z M114,19c1,0,1.8,0.8,1.8,1.8c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8C112.2,19.8,113,19,114,19z M114,19.3c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5C115.5,20,114.8,19.3,114,19.3z M114.1,19.8 c0.5,0,0.7,0.2,0.7,0.6c0,0.4-0.2,0.5-0.5,0.6l0.5,0.9h-0.4l-0.5-0.8h-0.3v0.8h-0.4v-2.1L114.1,19.8z M114,20.1l-0.4,0v0.6h0.3 c0.2,0,0.5,0,0.5-0.3C114.4,20.1,114.2,20.1,114,20.1z"
              />
            </svg>
          </a>
        </div>
      </div>
    </header>
  );
}

export default SiteHeader;
