import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from '@apollo/client/link/context';
import { page } from './cache';
import configs from 'configs';

const httpLink: any = createHttpLink({
  uri: configs.vehicles.uri
});
const midLayerHttpLink: any = createHttpLink({
  uri: configs.midLayer.uri
});

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token));
  } catch (e) {
    return null;
  }
};

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('user_auth_hash');

  if (token) {
    const idToken = parseJwt(token.split('.')[1]);
    const delta = 5 * 1000;
    const diff =
      new Date((idToken?.exp || 0) * 1000).getTime() - Date.now() - delta;
    const isTokenExpired = diff < 0;

    if (!isTokenExpired) {
      return {
        headers: {
          ...headers,
          Authorization: token
        }
      };
    }
  }

  return {
    headers: {
      ...headers,
      'x-api-key': configs.vehicles.apiKey
    }
  };
});

const authMidLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('user_auth_hash');

  if (token) {
    const idToken = parseJwt(token.split('.')[1]);
    const delta = 5 * 1000;
    const diff =
      new Date((idToken?.exp || 0) * 1000).getTime() - Date.now() - delta;
    const isTokenExpired = diff < 0;

    if (!isTokenExpired) {
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        }
      };
    }
  }

  return {
    headers: {
      ...headers,
      'x-api-key': configs.vehicles.apiKey
    }
  };
});

const latestArticlesLink: any = createHttpLink({
  uri: configs.latestArticles.uri
});

export const pageClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      // Type policy map
      Query: {
        fields: {
          // Field policy map for the Product type
          page: {
            read() {
              return page();
            }
          }
        }
      }
    }
  })
});

// Setup your client
export const vehicles = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink)
});

export const midLayer = new ApolloClient({
  cache: new InMemoryCache(),
  link: authMidLink.concat(midLayerHttpLink)
});

export const latestArticles = new ApolloClient({
  cache: new InMemoryCache(),
  link: latestArticlesLink
});
