import { makeVar } from '@apollo/client';
const REACT_APP_TEST_DATA = process.env.REACT_APP_TEST_DATA;
const REACT_APP_START_PAGE = process.env.REACT_APP_START_PAGE;

export const DEFAULT = { id: '', text: '' };

export const page = makeVar(REACT_APP_START_PAGE || 'home');
export const year = makeVar(
  REACT_APP_TEST_DATA ? { id: '1970', text: '' } : DEFAULT
);
export const make = makeVar(
  REACT_APP_TEST_DATA ? { id: '235', text: '' } : DEFAULT
);
export const model = makeVar(
  REACT_APP_TEST_DATA ? { id: '2175', text: '' } : DEFAULT
);
export const sub = makeVar(
  REACT_APP_TEST_DATA ? { id: '397', text: '' } : DEFAULT
);

export const vId = makeVar(
  REACT_APP_TEST_DATA ? { id: '42664', text: '' } : DEFAULT
);
