import './App.scss';
import React, { useEffect, useMemo, useState, lazy, Suspense } from 'react';
import axios from 'axios';
import configs from 'configs';
import { Helmet } from 'react-helmet';
import SiteHeader from 'components/SiteHeader';
import SiteFooter from 'components/SiteFooter';

import { useQuery } from '@apollo/client';
import { GET_LOCAL_STATE } from 'graphql/local';
import { pageClient } from 'clients';

const Home = lazy(() => import('containers/Home'));
const Years = lazy(() => import('containers/Years'));
const Makes = lazy(() => import('containers/Makes'));
const Models = lazy(() => import('containers/Models'));
const Subs = lazy(() => import('containers/Subs'));
const Summary = lazy(() => import('containers/Summary'));

function App() {
  const { data } = useQuery(GET_LOCAL_STATE, {
    client: pageClient
  });
  const Page = useMemo(() => {
    const pages = {
      home: Home,
      years: Years,
      makes: Makes,
      models: Models,
      subs: Subs,
      summary: Summary
    };
    return pages[data.page];
  }, [data.page]);

  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [firstLoad, setFirstLoad] = useState(true);

  const [needRefresh, setNeedRefresh] = useState(false);
  useEffect(() => {
    let intervalId = 0;
    let timeoutId = 0;

    function startRefresh() {
      setNeedRefresh(true);

      timeoutId = window.setTimeout(() => {
        setNeedRefresh(false);
      }, 90000);
    }
    if (window.location.href.indexOf('/refresh') === -1 && loggedIn) {
      intervalId = window.setInterval(() => {
        startRefresh();
      }, 3 * 60 * 1000);
    }
    if (window.location.href.indexOf('/refresh') > 0) {
      handleLogin();
    }

    return () => {
      window.clearInterval(intervalId);
      window.clearTimeout(timeoutId);
    };
  }, [setNeedRefresh, loggedIn]);

  useEffect(() => {
    console.log('v1.0.1');

    // Add utag
    const script = document.createElement('script');
    script.innerHTML = `(function(a,b,c,d){
      a='https://tags.tiqcdn.com/utag/hagerty/main/${configs.tealium.tag}/utag.js';
      b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
      a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
      })();`;
    const s = document.body.firstChild;
    if (s && s.parentNode) {
      s.parentNode.insertBefore(script, s);
    }

    const hostname = window.location.hostname;
    if (hostname === 'localhost') {
      console.log('Local development, mocking login...');
      setLoggedIn(true);
      setUsername('Keenan Staffieri');
    }

    const url = window.location.hash;
    const hash =
      url.indexOf('#') !== -1 ? url.substring(url.indexOf('=') + 1) : '';
    console.log('[App] login hash: ', hash);
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token));
      } catch (e) {
        return null;
      }
    };
    if (hash) {
      const idToken = parseJwt(hash.split('.')[1]);
      const subId = idToken.sub;

      console.log('[App] login row MembershipData:', idToken?.MembershipData);
      const membership = idToken?.MembershipData
        ? JSON.parse(idToken.MembershipData)
        : undefined;
      const firstname = membership?.firstName || '';
      const lastname = membership?.lastName || '';
      console.log('[App] login subId: ', subId);
      console.log('[App] login membership: ', membership);
      console.log('[App] login firstname: ', firstname);
      console.log('[App] login lastname: ', lastname);

      const fullname = firstname && lastname ? `${firstname} ${lastname}` : '';
      console.log('fullname: ', fullname);

      window.localStorage.setItem('user_name', fullname);
      if (hostname !== 'dev.hagerty.ca') {
        window.localStorage.setItem('user_auth_hash', hash.split('&')[0]);
      }

      // Remove hash and token from browser URL string
      window.history.pushState(
        '',
        document.title,
        window.location.pathname + window.location.search
      );

      setUsername(fullname);
      setLoggedIn(true);

      // Salesforce Campaign Trigger
      const uri = `${configs.salesforce.uri}?C=${configs.salesforce.campaignId}&U=${subId}`;
      axios({
        url: uri,
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': configs.salesforce.apiKey
        },
        data: {}
      })
        .then(({ data }) => {
          console.log('[salesforce] data: ', data);
        })
        .catch((error) => {
          console.log('[salesforce] error: ', error);
        });
    } else {
      // Check if user is logged in and set state
      const idTokenHash = window.localStorage.getItem('user_auth_hash');
      if (idTokenHash) {
        const idToken = parseJwt(idTokenHash.split('.')[1]);
        const delta = 30 * 1000;
        const diff =
          new Date(idToken.exp * 1000).getTime() - Date.now() - delta;
        const isTokenExpired = diff < 0;
        if (!isTokenExpired) {
          const userName = window.localStorage.getItem('user_name');
          console.log('[App] Already logged in as: ', userName);

          setUsername(userName as string);
          setFirstLoad(false);
          setLoggedIn(true);
        }
      }
    }
  }, []);

  const handleLogin = (e?: any) => {
    e?.preventDefault();

    const hostname = window.location.hostname;
    const authReq = {
      client_id: 'CanadaVehicle',
      redirect_uri: `https://${hostname}/apps/MyVehicle`,
      response_type: 'id_token',
      scope: 'openid profile email',
      nonce: (Date.now() + Math.random()) * Math.random(),
      acr_values: 'aal1'
    };

    const serialReq = Object.keys(authReq)
      .map((k) => {
        return encodeURIComponent(k) + '=' + encodeURIComponent(authReq[k]);
      })
      .join('&');
    console.log('[handleLogin] serialReq: ', serialReq);

    // Redirect user to login URL
    window.location.href = configs.login.uri + serialReq;
  };

  const handleLogout = () => {
    window.localStorage.removeItem('user_name');
    window.localStorage.removeItem('user_auth_hash');
    const hostname = window.location.hostname;
    window.location.href = `https://${hostname}/authenticate/logout`;
  };

  return (
    <>
      <Helmet>
        <script
          src={`https://tags.tiqcdn.com/utag/hagerty/main/${configs.tealium.tag}/utag.sync.js`}
        />
      </Helmet>
      <div id="page-wrap" className={`page-wrap page-wrap--${data.page}`}>
        <SiteHeader
          loggedIn={loggedIn}
          username={username}
          phoneNumber="877-922-3398"
          onLoginClick={handleLogin}
          onLogoutClick={handleLogout}
        />
        <div className="page-content-wrap">
          <Suspense fallback={<div className="loading-spacer" />}>
            <Page
              firstLoad={firstLoad}
              loggedIn={loggedIn}
              onLoginClick={handleLogin}
              onFirstLoad={setFirstLoad}
            />
          </Suspense>
        </div>

        <SiteFooter />
        {needRefresh && (
          <iframe
            title="oauth-refresh"
            src={'/apps/MyVehicle/refresh'}
            frameBorder={0}
            style={{
              width: 0,
              height: 0,
              border: '0 none',
              position: 'absolute'
            }}
          />
        )}
      </div>
    </>
  );
}

export default App;
